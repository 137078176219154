// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyAMfjXCQP330z5IbOUO1ZSjrMttuxTJwik",
  authDomain: "bobs-bee0c.firebaseapp.com",
  databaseURL: "https://bobs-bee0c-default-rtdb.firebaseio.com",
  projectId: "bobs-bee0c",
  storageBucket: "bobs-bee0c.appspot.com",
  messagingSenderId: "68972832722",
  appId: "1:68972832722:web:7d8e668df2b5032010ca0b",
  measurementId: "G-948SZMZJYM"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };